







import { Vue, Component } from "vue-property-decorator";
import SendExposeCompanyInfo from "@/components/expose-view/SendExposeCompanyInfo.vue";
import SendExposeForm from "@/components/expose-view/SendExposeForm.vue";

@Component({
  components: {
    SendExposeCompanyInfo,
    SendExposeForm,
  },
})
export default class SendExposeView extends Vue {}
