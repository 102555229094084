














































































































































































































import { Component, Mixins } from "vue-property-decorator";
import RulesMixin from "@/mixins/RulesMixin";
import Axios from "axios";
import { capitalizeAllWords } from "@/utils/string";

@Component
export default class SendExposeForm extends Mixins(RulesMixin) {
  isFormValid = false;
  isFormSubmitting = false;
  wasSubmitted = false;
  formData = {
    companyName: "",
    firstName: "",
    lastName: "",
    street: "",
    zipCode: "",
    city: "",
    email: "",
    phone: "",
    note: "",
    // regions: [],
    // industries: []
  };
  projectId = "";

  capitalizeAllWords = capitalizeAllWords;

  async submitForm(): Promise<void> {
    try {
      this.isFormSubmitting = true;
      await Axios.post(
        `/api/v2/website/orderExpose/${this.projectId}`,
        this.formData
      );
      this.wasSubmitted = true;
    } catch (e) {
      this.$snackbarError(this.$tc("apiErrors.unableToSave"));
    } finally {
      this.isFormSubmitting = false;
    }
  }
}
